app.
directive('toggleArrowRotation', ['$window', '$timeout', function ($window, $timeout) {
    return {
        restrict: 'C',
        replace: true,
        //transclude: element,
        scope: true,
        link: function (scope, elem, attrs) {//alert("LOOL");
            elem.click(
            function () {
                var divId = elem[0].id;
                var divElement = document.getElementById(divId);
                if (hasClass(divElement, "icon-arrow-up")) {
                    $('#' + divId).removeClass('icon-arrow-up');
                    $('#' + divId).addClass('icon-arrow_down');
                } else if (hasClass(divElement, "icon-arrow_down")) {
                    $('#' + divId).removeClass('icon-arrow_down');
                    $('#' + divId).addClass('icon-arrow-up');
                }
            });


            function hasClass(element, nameOfClass) {
                return (" " + element.className + " ").indexOf(" " + nameOfClass + " ") > -1;
            }//function hasClass()
        }
    }
}]);