app
    .controller('createStatisticsController', ['$scope', '$rootScope', '$http', '$route', '$location', '$q', '$timeout', '$anchorScroll', '$routeParams', 'apiUrl', '$filter',
        function ($scope, $rootScope, $http, $route, $location, $q, $timeout, $anchorScroll, $routeParams, apiUrl, $filter) {

            $scope.states = {};
            $scope.options = {};

            getContent();


            function getContent() {
                $http({
                    method: 'GET',
                    url: apiUrl + 'CustomContentService/GetStaticContentJson/' + lang + '/' + '4d9d8643-1fc6-6ee6-b81a-ff0000af5e57' + '?callback=JSON_CALLBACK'
                }).success(function (data) {
                    var json = JSON.parse(JSON.stringify(data));
                    $scope.states.Content = json["Html"];
                    //console.log(scope.states.Content);
                });
            };

            GetDataCollectionObvestilos();

            function GetDataCollectionObvestilos() {
                $http({
                    method: 'GET',
                    url: apiUrl + 'CustomContentTypeService/GetDataCollectionObvestilos/' + lang
                }).success(function (data) {

                    var obvestiloPrikazano = JSON.parse(JSON.stringify(data[0]["ObvestiloSplosno"]));
                    $scope.states.obvestiloPrikazano = obvestiloPrikazano;

                    //console.log("Prikazano obvestilo je: " + $scope.states.obvestiloPrikazano);

                });
            };

            $scope.globalVariables.pageTitle = function () {
                return $filter('translate')('CreateStatistics');
            };
        }]);