app
    .controller('dataController', ['$scope', '$rootScope', '$http', '$route', '$location', '$q', '$timeout', '$anchorScroll', '$routeParams', 'lang', 'apiUrl', '$translate',
        function ($scope, $rootScope, $http, $route, $location, $q, $timeout, $anchorScroll, $routeParams, lang, apiUrl, $translate) {

          
            $scope.states = {};
            $scope.options = {};

            $scope.states.jezik = lang;
            if ($scope.states.jezik == 'sl') {
                $scope.states.jezik = '';
            } else {
                $scope.states.jezik = 'en/'
            }

            
            $scope.states.DataCollectionPages = [
            { id: "1", name: "CreateStatistics", view: "" + $scope.states.jezik + "DataCollection/CreateStatistics", slName: "Soustvarjajte statistiko", enName: "Create statistics", show:false },
            { id: "2", name: "Enterprises", view: "" + $scope.states.jezik + "DataCollection/Enterprises", slName: "Podjetja in organizacije ", enName: "Enterprises", show:false },
            { id: "3", name: "Households", view: "" + $scope.states.jezik + "DataCollection/Households", slName: "Osebe in gospodinjstva", enName: "Households", show:true },
            { id: "4", name: "Intrastat", view: "" + $scope.states.jezik + "DataCollection/Intrastat", slName: "Intrastat", enName: "Intrastat", show: true },
            { id: "5", name: "Ajpes", view: "" + $scope.states.jezik + "DataCollection/Ajpes", slName: "Sodelovanje z AJPES", enName: "Collaboration with AJPES", show: true },
            { id: "6", name: "Interviewers", view: "" + $scope.states.jezik + "DataCollection/Interviewers", slName: "Prijava za anketarje", enName: "Interviewers", show:true }
            ];

            function getBreadCrumbsDataCollection(_name, _fullName) {
                $http({
                    method: 'GET',
                    url: lang + '/DataCollectionApi/BreadCrumbsDataCollection?name=' + _name + '&fullName=' + _fullName
                }).success(function (data) {
                    $scope.states.BreadCrumbs = data; //alert(data[3]["Name"]);
                });
            } // getBreadCrumbsDataCollection

            $scope.isSelected = function (_name) {
                var urlArray = $location.path().split('/');
                var subpage = urlArray[urlArray.length - 1];
                if (subpage) {
                    if (_name == subpage) {
                        return true;
                    }//if
                } else {
                    return false;
                }//else
            }


            $scope.getFieldClass = function (_index, _name) {
                if (_index == 0) {//prvi element v ng-repeat 
                    if ($scope.isSelected(_name)) {
                        return 'first-item-selected';
                    } else {
                        return 'first-field-related-item';
                    }//else
                }//if
                else {
                    if ($scope.isSelected(_name)) {
                        return 'field-related-item-selected';
                    } else {
                        return '';
                    }//else
                }//else
            };//getFieldClass


            $scope.states.refreshBreadCrumbs = function () {
                var subpageNameCurrent;
                var urlArrayCurrent = $location.path().split('/');
                var subpageCurrent = urlArrayCurrent[urlArrayCurrent.length - 1];
                for (var j = 0; j < $scope.states.DataCollectionPages.length; j++) {
                    if (lang == 'sl') {
                        //alert("slovenski");
                        for (var k = 0; k < $scope.states.DataCollectionPages.length; k++) {
                            if ($scope.states.DataCollectionPages[k]["name"] == subpageCurrent) {
                                subpageNameCurrent = $scope.states.DataCollectionPages[k]["slName"];
                                $scope.states.currentSubpageTitle = $scope.states.DataCollectionPages[k]["slName"];
                            }
                        }
                    } else {
                        for (var k = 0; k < $scope.states.DataCollectionPages.length; k++) {
                            if ($scope.states.DataCollectionPages[k]["name"] == subpageCurrent) {
                                subpageNameCurrent = $scope.states.DataCollectionPages[k]["enName"];
                                $scope.states.currentSubpageTitle = $scope.states.DataCollectionPages[k]["enName"];
                            }
                        }
                    }
                }
                //var subpageCurrent = "lol"; var subpageNameCurrent = "SUB NAME";
                getBreadCrumbsDataCollection(subpageCurrent, subpageNameCurrent);
            }//refreshBreadCrumbs
            //DAVID


               



            $scope.$on('LastRepeaterElement', function () {
                $scope.$broadcast("LastRepeaterElementFromController");
            });
        }]);