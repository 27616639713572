app
    .controller('householdsController', ['$scope', '$rootScope', '$http', '$route', '$location', '$q', '$timeout', '$anchorScroll', '$routeParams', 'apiUrl', '$filter', 'lang',
        function ($scope, $rootScope, $http, $route, $location, $q, $timeout, $anchorScroll, $routeParams, apiUrl, $filter, lang) {

            $scope.selection = {};
            $scope.questionary = {};


            $scope.states = {};
            $scope.options = {};

            $scope.lang = lang;

            $scope.states.HtmlContent;
            $scope.states.JsContent;

            getContent();
            getContent1();
            

            function getContent() {
                $http({
                    method: 'GET',
                    url: apiUrl + 'CustomContentService/GetStaticContentJson/' + $scope.getLanguage() + '/' + '2e9d8643-1fc6-6ee6-b81a-ff0000af5e57' + '?callback=JSON_CALLBACK'
                }).success(function (data) {
                    var json = JSON.parse(JSON.stringify(data));
                    $scope.states.Content = json["Html"];
                    $scope.states.JsContent = json["JavaScript"];
                });
            };

            function getContent1() {
                $http({
                    method: 'GET',
                    url: apiUrl + 'CustomContentService/GetStaticContentJson/' + $scope.getLanguage() + '/' + 'f2a08643-1fc6-6ee6-b81a-ff0000af5e57' + '?callback=JSON_CALLBACK'
                }).success(function (data) {
                    var json = JSON.parse(JSON.stringify(data));
                    $scope.states.Content1 = json["Html"];
                    $scope.states.JsContent1 = json["JavaScript"];
                });
            };
            
            GetDataCollectionObvestilos();

            function GetDataCollectionObvestilos() {
                $http({
                    method: 'GET',
                    url: apiUrl + 'CustomContentTypeService/GetDataCollectionObvestilos/' + lang
                }).success(function (data) {

                    var obvestiloGospodinjstva = JSON.parse(JSON.stringify(data[0]["ObvestiloZaGospodinjstva"]));
                   // var obvestiloSplosno = JSON.parse(JSON.stringify(data[0]["ObvestiloSplosno"]));

                    //var obvestiloPrikazano;

                    //if (obvestiloGospodinjstva != null) { // Če obvestilo za gospodinjstva ni prazno
                    //    obvestiloPrikazano = obvestiloGospodinjstva; //Potem bo prikazano obvestilo za gospodinjstva
                    //}
                    //else {
                    //    obvestiloPrikazano = obvestiloSplosno; //sicer splošno obvestilo
                    //}

                    //$scope.states.obvestiloPrikazano = obvestiloPrikazano;
                  //  $scope.states.obvestiloSplosno = obvestiloSplosno;
                    $scope.states.obvestiloGospodinjstva = obvestiloGospodinjstva;


                });
            };

            //$scope.globalVariables.pageTitle = function () {
            //    return $filter('translate')('Enterprises');
            //};


            $scope.setQuestionaries = function () {
                
                $http({
                    method: 'GET',
                    url: "DataCollectionApi/GetQuestionariesForReportingUnit/" + $scope.selection.ReportingUnit
                }).success(function (data) {

                    $scope.options.availableQuestionaries = data;

                    if (data.length > 0) {
                        $scope.selection.Questionary = null; //$scope.options.availableQuestionaries[0];
                    }
                    initSelect2();

                });
            }

            function initSelect2() {
                var langZadetki, placeholder;
                placeholder = "Izberite raziskovanje ...";
                langZadetki = {
                    noResults: function () {
                        return "Ni najdenih rezultatov.";
                    }
                };
                $("#select").select2({
                    placeholder: placeholder,
                    language: langZadetki
                });
            };

            $scope.getQuestionary = function () {
                //var value = $('#selectedQ').val();
                //$scope.selection.Questionary = $('#dataListH [value="' + value + '"]')[0].id

                $http({
                    method: 'GET',
                    url: "DataCollectionApi/GetQuestionary/" + $scope.selection.Questionary

                }).success(function (data) {

                    $scope.questionary = data;
                    $scope.states.showResults = true;
                    $scope.states.showBasicQuestions = false;
                    $scope.states.showQuestions = true;
                    $scope.states.btnBasicQuestions = "surs-faq-btn";
                    $scope.states.btnQuestions = "surs-faq-btn-active";
                    //console.log($scope.questionary);

                });
            };

            $scope.getFAQ = function () {
                $http({
                    method: 'GET',
                    url: "DataCollectionApi/GetFAQ"
                }).success(function (data) {

                    $scope.faq = data;

                });
            };

            $scope.showBasicQuestions = function () {
                $scope.states.showBasicQuestions = true;
                $scope.states.showQuestions = false;
                $scope.states.btnBasicQuestions = "surs-faq-btn-active";
                $scope.states.btnQuestions = "surs-faq-btn";
            }

            $scope.showQuestions = function () {
                $scope.states.showBasicQuestions = false;
                $scope.states.showQuestions = true;
                $scope.states.btnBasicQuestions = "surs-faq-btn";
                $scope.states.btnQuestions = "surs-faq-btn-active";
            }

            $scope.showGeneralQuestions = function () {
                $scope.states.showGeneral = true;
                $scope.states.showEnterprises = false;
                $scope.states.showIndividuals = false;
            }

            $scope.showEnterprisesQuestions = function () {
                $scope.states.showGeneral = false;
                $scope.states.showEnterprises = true;
                $scope.states.showIndividuals = false;
            }

            $scope.showIndividualsQuestions = function () {
                $scope.states.showGeneral = false;
                $scope.states.showEnterprises = false;
                $scope.states.showIndividuals = true;
            }


            //////////////////  CHOICES  /////////////////////





            //////////////////  Execute when controller is created   /////////////////////

            if (lang == "sl") {
                $scope.selection.ReportingUnit = 2;

                $scope.setQuestionaries();


                $scope.states.showResults = false;
                $scope.states.showQuestions = false;
                $scope.states.showBasicQuestions = false;

                $scope.states.btnBasicQuestions = "surs-faq-btn";
                $scope.states.btnQuestions = "surs-faq-btn";

            } else {

                $scope.getFAQ();

                $scope.states.showGeneral = true;
                $scope.states.showEnterprises = false;
                $scope.states.showIndividuals = false;

            }

            $scope.globalVariables.pageTitle = function () {
                return $filter('translate')('Households');
            };
            

        }]);


    

