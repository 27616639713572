
//$(document).ready(function () {
//    console.log("ALO");
//        $(".dogodki-collapsed").on('click', function () {   //CLASSI VNEŠENI PREKO SITEFINITY-JA NISO NAJDENI.
//            console.log("PIVO");
//            var icon = this.getElementsByTagName('em')[0];
//            icon.classList.toggle("icon-plus-white");
//            icon.classList.toggle("icon-minus-white");
//        });
//});

//$(".dogodki-collapsed").on('click', function () {
//    var icon = this.getElementsByTagName('em')[0];
//    icon.classList.toggle("icon-plus-white");
//    icon.classList.toggle("icon-minus-white");
//});

$(".dogodki-collapsed").on('click', function () {
    var icon = this.find(".iconPlusMinusDC > span")[0];
    icon.classList.toggle("icon-plus-white1");
    icon.classList.toggle("icon-minus-white1");
});

//togglePlusMinus = function (element) {
//    $(element).find('span').toggleClass('icon-plus-white1 icon-minus-white1');
//}

//function clean() {
//     document.getElementById("selectedQ").value = "";
//};

function toggleMoreLess() {
    
    var iconContainer = document.createElement("div");
    var icon = document.createElement("i");

    if ($("#notificationsCollapseButton").hasClass("collapsed")) {
        if (document.getElementsByTagName('html')[0].getAttribute('lang') == 'en') {
            $("#notificationsCollapseButton").text('Hide previous news');
        }
        else {
            $("#notificationsCollapseButton").text('Skrij pretekla obvestila');
        }
   
        icon.classList.add("icon-arrow-up");
        icon.classList.add("intrastat-show-more-icon");
        iconContainer.classList.add("show-more-icon-container");
        $("#notificationsCollapse").collapse("show");
        $("#notificationsCollapseButton").removeClass("collapsed");
        /*icon.classList.add("up");*/
        

    } else {
        if (document.getElementsByTagName('html')[0].getAttribute('lang') == 'en') {
            $("#notificationsCollapseButton").text('Show previous news');
        }
        else {
            $("#notificationsCollapseButton").text('Prikaži pretekla obvestila');
        }
        icon.classList.add("icon-arrow_down");
        icon.classList.add("intrastat-show-more-icon");
        iconContainer.classList.add("show-more-icon-container");
        $("#notificationsCollapse").collapse("hide");
        $("#notificationsCollapseButton").addClass("collapsed");
        /*icon.classList.add("down");*/
    }
    iconContainer.append(icon);
    $("#notificationsCollapseButton").append(iconContainer);
}

function togglePlusMinus(element) {
    var icon = $(element).find(".iconPlusMinusDC > span")[0];
    icon.classList.toggle("icon-plus-white1");
    icon.classList.toggle("icon-minus-white1");
}