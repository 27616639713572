//angular.module('methodsPageApp')
app
.directive('overflowDots', ['$window', '$timeout', function ($window, $timeout) {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, element, attrs) {
            function isOverFlow(element) {
                return $(element)[0].clientHeight < $(element)[0].scrollHeight;
            } // isOverFlow


            function toggleRelatedDots() {
                if (isOverFlow($('.field-related-links'))) {
                    $('.field-related-dots').css({'display': 'block'});
                } // if
                else if ($('.field-related-links').css('overflow') == 'hidden') {
                    $('.field-related-dots').css({ 'display': 'none' });
                } // else
            } // toggleRelatedDots


            var w = angular.element($window);
            w.bind('resize', function () {
                toggleRelatedDots();
            });

            $('.field-related-dots').on('click', function () {
                $('.field-related-links').toggleClass('hide-overflow')
                    .toggleClass('show-overflow');
            });

            // timeout used to fire toggleRelatedDots last element added
            scope.$on('LastRepeaterElementFromController', function () {
                setTimeout(toggleRelatedDots, 200);
            });        

        } // link
    };
}]);
