app
    .controller('enterprisesController', ['$scope', '$rootScope', '$http', '$route', '$location', '$q', '$timeout', '$anchorScroll', '$routeParams', 'apiUrl', '$filter', 'lang',
        function ($scope, $rootScope, $http, $route, $location, $q, $timeout, $anchorScroll, $routeParams, apiUrl, $filter, lang) {

            $scope.selection = {};
            $scope.questionary = {};

            $scope.states = {};
            $scope.options = {};

            $scope.lang = lang;

            $scope.states.HtmlContent;
            $scope.states.JsContent;

            $scope.states.HtmlContent1;
            $scope.states.JsContent1;

            getContent();
            getContent1();

            function getContent() {
                $http({
                    method: 'GET',
                    url: apiUrl + 'CustomContentService/GetStaticContentJson/' + $scope.getLanguage() + '/' + '969d8643-1fc6-6ee6-b81a-ff0000af5e57' + '?callback=JSON_CALLBACK'
                }).success(function (data) {
                    var json = JSON.parse(JSON.stringify(data));
                    $scope.states.HtmlContent = json["Html"];
                    $scope.states.JsContent = json["JavaScript"];

                    
                
                });
            };

            function getContent1() {
                $http({
                    method: 'GET',
                    url: apiUrl + 'CustomContentService/GetStaticContentJson/' + $scope.getLanguage() + '/' + '6ca08643-1fc6-6ee6-b81a-ff0000af5e57' + '?callback=JSON_CALLBACK'
                }).success(function (data) {
                    var json = JSON.parse(JSON.stringify(data));
                    $scope.states.HtmlContent1 = json["Html"];
                    $scope.states.JsContent1 = json["JavaScript"];
                  
                });
            };

            GetDataCollectionObvestilos();

            function GetDataCollectionObvestilos() {
                $http({
                    method: 'GET',
                    url: apiUrl + 'CustomContentTypeService/GetDataCollectionObvestilos/' + lang
                }).success(function (data) {

                    var obvestiloPodjetja = JSON.parse(JSON.stringify(data[0]["ObvestiloZaPodjetja"]));
                   // var obvestiloSplosno = JSON.parse(JSON.stringify(data[0]["ObvestiloSplosno"]));

                    //PREJŠNJA VERZIJA : Prikaz le enega obvestila

                    //var obvestiloPrikazano;

                    //if (obvestiloPodjetja != null) { // Če obvestilo za podjetja ni prazno
                    //    obvestiloPrikazano = obvestiloPodjetja; //Potem bo prikazano obvestilo za podjetja
                    //}
                    //else {
                    //    obvestiloPrikazano = obvestiloSplosno; //sicer splošno obvestilo
                    //}
                    
                    /*    $scope.states.obvestiloPrikazano = obvestiloPrikazano;*/
                   // $scope.states.obvestiloSplosno = obvestiloSplosno;
                    $scope.states.obvestiloPodjetja = obvestiloPodjetja;

                });
            };

            //$scope.globalVariables.pageTitle = function () {
            //    return $filter('translate')('Enterprises');
            //};

            //////////////////  FUNCTIONS  /////////////////////

            //-------------- SEARCH FUNCTIONS ----------------//

            

                $scope.setQuestionaries = function () {

                    $http({
                        method: 'GET',
                        url: $scope.getLanguage() + "/DataCollectionApi/GetQuestionariesForReportingUnit/" + $scope.selection.ReportingUnit
                    }).success(function (data) {
                        $scope.options.availableQuestionaries = data;
                        if (data.length > 0) {
                            $scope.selection.Questionary = null; //$scope.options.availableQuestionaries[0];
                        }
                        initSelect2();
                    });
                }



            $scope.getQuestionary = function () {
                ////console.log($scope.selection.Questionary);
                //var value = $('#selectedQ').val();
                //$scope.selection.Questionary = $('#dataListE [value="' + value + '"]')[0].id

                $http({
                    method: 'GET',
                    url: $scope.getLanguage() + "/DataCollectionApi/GetQuestionary/" +  $scope.selection.Questionary
                    
                }).success(function (data) {

                    $scope.questionary = data;
                    $scope.states.showResults = true;
                    $scope.states.showBasicQuestions = false;
                    $scope.states.showQuestions = true;
                    $scope.states.btnBasicQuestions = "surs-faq-btn";
                    $scope.states.btnQuestions = "surs-faq-btn-active";


                });
            };

            $scope.getFAQ = function () {
                $http({
                    method: 'GET',
                    url: "DataCollectionApi/GetFAQ"
                }).success(function (data) {

                    $scope.faq = data;

                });
            };

            $scope.showBasicQuestions = function () {
                $scope.states.showBasicQuestions = true;
                $scope.states.showQuestions = false;
                $scope.states.btnBasicQuestions = "surs-faq-btn-active";
                $scope.states.btnQuestions = "surs-faq-btn";
            }

            $scope.showQuestions = function () {
                $scope.states.showBasicQuestions = false;
                $scope.states.showQuestions = true;
                $scope.states.btnBasicQuestions = "surs-faq-btn";
                $scope.states.btnQuestions = "surs-faq-btn-active";
            }

            $scope.showGeneralQuestions = function () {
                $scope.states.showGeneral = true;
                $scope.states.showEnterprises = false;
                $scope.states.showIndividuals = false;
            }

            $scope.showEnterprisesQuestions = function () {
                $scope.states.showGeneral = false;
                $scope.states.showEnterprises = true;
                $scope.states.showIndividuals = false;
            }

            $scope.showIndividualsQuestions = function () {
                $scope.states.showGeneral = false;
                $scope.states.showEnterprises = false;
                $scope.states.showIndividuals = true;
            }


            //////////////////  CHOICES  /////////////////////





            //////////////////  Execute when controller is created   /////////////////////

            //if (lang == "sl") {
            //    //$scope.selection.ReportingUnit = 1;

            //    //$scope.setQuestionaries();

            //    //$scope.states.showResults = false;
            //    //$scope.states.showQuestions = false;
            //    //$scope.states.showBasicQuestions = false;

            //    //$scope.states.btnBasicQuestions = "surs-faq-btn";
            //    //$scope.states.btnQuestions = "surs-faq-btn";
            //} else {

            //    //$scope.getFAQ();

            //    //$scope.states.showGeneral = true;
            //    //$scope.states.showEnterprises = false;
            //    //$scope.states.showIndividuals = false;

            //}
            $scope.selection.ReportingUnit = 1;

            $scope.setQuestionaries();

            $scope.states.showResults = false;
            $scope.states.showQuestions = false;
            $scope.states.showBasicQuestions = false;

            $scope.states.btnBasicQuestions = "surs-faq-btn";
            $scope.states.btnQuestions = "surs-faq-btn";

    //SELECT DEMO

            function initSelect2() {
                var langZadetki, placeHolder;
                //placeholder = "Izberite raziskovanje ...";
                //langZadetki = {
                //    noResults: function () {
                //        return "Ni najdenih rezultatov.";
                //    }
                //};
                if (lang == 'sl') {
                    placeHolder = "Izberite raziskovanje ...";
                    langZadetki = {
                        noResults: function () {
                            return "Ni najdenih rezultatov."
                        } };
                }
                else {
                    placeHolder = "Select a survey...";
                    langZadetki = {
                        noResults: function () { return "No results found." }};
                };


                $("#select").select2({
                    placeholder: placeHolder,
                    language: langZadetki
                });
            };

            $scope.globalVariables.pageTitle = function () {
                return $filter('translate')('Enterprises');
            };

        }]);

