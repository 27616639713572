app
    .controller('intrastatController', ['$scope', '$rootScope', '$http', '$route', '$location', '$q', '$timeout', '$anchorScroll', '$routeParams', 'apiUrl', '$filter',
        function ($scope, $rootScope, $http, $route, $location, $q, $timeout, $anchorScroll, $routeParams, apiUrl, $filter) {

            $scope.states = {};
            $scope.options = {};
            

            getContent();
            getCollectionOfPojasnilos();

            function getContent() {
                $http({
                    method: 'GET',
                    url: apiUrl + 'CustomContentService/GetStaticContentJson/' + $scope.getLanguage() + '/' + '789d8643-1fc6-6ee6-b81a-ff0000af5e57' + '?callback=JSON_CALLBACK'
                }).success(function (data) {
                    var json = JSON.parse(JSON.stringify(data));
                });
            };

            getCollectionOfObvestilos();

            function getCollectionOfObvestilos() {
                $http({
                    method: 'GET',
                    url: apiUrl + 'CustomContentTypeService/GetCollectionOfObvestilos/' + $scope.getLanguage()
                }).success(function (data) {
                    var json = JSON.parse(JSON.stringify(data));
                  /*  console.log("OBVESTILA" + json[0].VsebinaObvestila);*/

                    $scope.states.obvestilos = json;

                    //for (var i = 0; i < json.length; i++) {
                    //    json[i].VsebinaObvestila.replaceAll("&scaron;", "").replaceAll("&ndash","");
                    //    console.log(json[i].VsebinaObvestila);
                    //}

                   

                    $scope.states.AktualnaObvestila = json.reverse().slice(0, 2);
                 

                    $scope.states.PrejsnjaObvestila = json.slice(2, json.length);

                    $scope.states.maxIndex = json.length;

                    //console.log("AKTUALNO " + $scope.states.AktualnaObvestila);
                });
            };

            function getCollectionOfPojasnilos() {
                $http({
                    method: 'GET',
                    url: apiUrl + 'CustomContentTypeService/GetCollectionOfPojasnilos/' + $scope.getLanguage()
                }).success(function (data) {
                    var json = JSON.parse(JSON.stringify(data));
                   /* console.log("POJASNILA" + json[0].Vsebina);*/

                    $scope.states.VsaPojasnila = json;


                    var maxIndex = json.length;

                });
            }

            



            $scope.globalVariables.pageTitle = function () {
                return $filter('translate')('Intrastat');
            };
        }]);