app
    .controller('interviewersController', ['$scope', '$rootScope', '$http', '$route', '$location', '$q', '$timeout', '$anchorScroll', '$routeParams', 'apiUrl', '$filter',
        function ($scope, $rootScope, $http, $route, $location, $q, $timeout, $anchorScroll, $routeParams, apiUrl, $filter) {

            $scope.states = {};
            $scope.options = {};
            
            $scope.states = {};
            $scope.options = {};

            $scope.lang = lang;

            $scope.states.HtmlContent;
            $scope.states.JsContent; 

            getContent();

            function getContent() {
                $http({
                    method: 'GET',
                    url: apiUrl + 'CustomContentService/GetStaticContentJson/' + lang + '/' + 'c8a58643-1fc6-6ee6-b81a-ff0000af5e57' + '?callback=JSON_CALLBACK'
                }).success(function (data) {
                    var json = JSON.parse(JSON.stringify(data)); 
                    $scope.states.Content = json["Html"];
                });
            };
            
            GetDataCollectionObvestilos(); 

            function GetDataCollectionObvestilos() { 
                $http({
                    method: 'GET',
                    url: apiUrl + 'CustomContentTypeService/GetDataCollectionObvestilos/' + lang
                }).success(function (data) {

                    var obvestiloZaAnketarje = JSON.parse(JSON.stringify(data[0]["ObvestiloZaAnketarje"]));
                    var obvestiloSplosno = JSON.parse(JSON.stringify(data[0]["ObvestiloSplosno"]));

                    //var obvestiloPrikazano;

                    //if (obvestiloGospodinjstva != null) { // Če obvestilo za gospodinjstva ni prazno
                    //    obvestiloPrikazano = obvestiloGospodinjstva; //Potem bo prikazano obvestilo za gospodinjstva
                    //}
                    //else {
                    //    obvestiloPrikazano = obvestiloSplosno; //sicer splošno obvestilo
                    //}

                    //$scope.states.obvestiloPrikazano = obvestiloPrikazano;
                    $scope.states.obvestiloSplosno = obvestiloSplosno;
                    $scope.states.obvestiloZaAnketarje = obvestiloZaAnketarje;


                });
            };            


            $scope.globalVariables.pageTitle = function () {
                return $filter('translate')('Interviewers');
            };
        }]);