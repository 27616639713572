app
    .controller('ajpesController', ['$scope', '$rootScope', '$http', '$route', '$location', '$q', '$timeout', '$anchorScroll', '$routeParams', 'apiUrl', '$filter', 'lang',
        function ($scope, $rootScope, $http, $route, $location, $q, $timeout, $anchorScroll, $routeParams, apiUrl, $filter) {

            $scope.states = {};
            $scope.options = {};

            $scope.lang = lang;

            getContent();

            function getContent() {
                $http({
                    method: 'GET',
                    url: apiUrl + 'CustomContentService/GetStaticContentJson/' + lang + '/' + '559d8643-1fc6-6ee6-b81a-ff0000af5e57' + '?callback=JSON_CALLBACK'
                }).success(function (data) {
                    var json = JSON.parse(JSON.stringify(data)); 
                    $scope.states.Content = json["Html"];
                });
            };

            GetDataCollectionObvestilos();

            function GetDataCollectionObvestilos() {
                $http({
                    method: 'GET',
                    url: apiUrl + 'CustomContentTypeService/GetDataCollectionObvestilos/' + lang 
                }).success(function (data) {

                    var obvestiloAjpes = JSON.parse(JSON.stringify(data[0]["ObvestiloAjpes"]));
                    var obvestiloSplosno = JSON.parse(JSON.stringify(data[0]["ObvestiloSplosno"]));

                    //PREJŠNJA VERZIJA : Prikaz le enega obvestila

                    //var obvestiloPrikazano;

                    //if (obvestiloPodjetja != null) { // Če obvestilo za podjetja ni prazno
                    //    obvestiloPrikazano = obvestiloPodjetja; //Potem bo prikazano obvestilo za podjetja
                    //}
                    //else {
                    //    obvestiloPrikazano = obvestiloSplosno; //sicer splošno obvestilo
                    //}
                    
                    /*    $scope.states.obvestiloPrikazano = obvestiloPrikazano;*/
                    $scope.states.obvestiloSplosno = obvestiloSplosno;
                    $scope.states.obvestiloAjpes = obvestiloAjpes;

                    console.log("TEST: " + $scope.states.obvestiloAjpes)

                });
            }; 



            $scope.globalVariables.pageTitle = function () {
                return $filter('translate')('Ajpes');
            };
        }]);