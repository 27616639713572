app.
directive('collapseArrow', ['$window', '$timeout', '$http', 'apiUrl', '$sce', '$compile', function ($window, $timeout, $http, apiUrl, $sce, $compile) {
    return {
        restrict: 'C',
        replace: false,
        transclude: false,
        scope: true,
        link: function (scope, elem, attrs) {

            $http({
                method: 'GET',
                url: apiUrl + 'CustomContentService/GetStaticContentJson/' + scope['lan'] + '/' + 'bac58143-1fc6-6ee6-b81a-ff0000af5e57' + '?callback=JSON_CALLBACK'
            }).success(function (data) {
                var json = JSON.parse(JSON.stringify(data));
                var newElement = json["Html"];
                var content = $compile(newElement)(scope);
                console.log(content);
                $compile(newElement)(scope);
                for (var i = 0 ; i < content.length; i++) {
                    elem.append(content[i]); //alert(content[0]);
                }
            });

        }
    }
}]);