var app = angular.module('dataCollectionPageApp', ['ngRoute', 'genericModule', 'templates'])
    .constant('CollapseIcon', 'icon-arrow-up')
    .constant('ExpandIcon', 'icon-arrow_down')
    .config(['$routeProvider', '$locationProvider', '$translateProvider',
        function ($routeProvider, $locationProvider, $translateProvider) {
            // enable nice routes
            $locationProvider.html5Mode({
                enabled: true,
                requireBase: false
            });

            //set routes

            $routeProvider.when("/DataCollection/CreateStatistics", {
                templateUrl: "App/DataCollection/views/_CreateStatistics.html",
                caseInsensitiveMatch: true
            });
            $routeProvider.when("/:lang/DataCollection/CreateStatistics", {
                templateUrl: "App/DataCollection/views/_CreateStatistics.html",
                caseInsensitiveMatch: true
            });

           

            $routeProvider.when("/DataCollection/Enterprises", {
                templateUrl: "App/DataCollection/views/_Enterprises.html",
                caseInsensitiveMatch: true
            });
            $routeProvider.when("/:lang/DataCollection/Enterprises", {
                templateUrl: "App/DataCollection/views/_Enterprises.html",
                caseInsensitiveMatch: true
            });

            $routeProvider.when("/DataCollection/Households", {
                templateUrl: "App/DataCollection/views/_Households.html",
                caseInsensitiveMatch: true
            });
            $routeProvider.when("/:lang/DataCollection/Households", {
                templateUrl: "App/DataCollection/views/_Households.html",
                caseInsensitiveMatch: true
            });

            $routeProvider.when("/DataCollection/Intrastat", {
                templateUrl: "App/DataCollection/views/_intrastat.html",
                caseInsensitiveMatch: true
                
            });
            $routeProvider.when("/:lang/DataCollection/Intrastat", {
                templateUrl: "App/DataCollection/views/_intrastat.html",
                caseInsensitiveMatch: true
            });

            $routeProvider.when("/DataCollection/Ajpes", {
                templateUrl: "App/DataCollection/views/_Ajpes.html",
                caseInsensitiveMatch: true
            });
            $routeProvider.when("/:lang/DataCollection/Ajpes", {
                templateUrl: "App/DataCollection/views/_Ajpes.html",
                caseInsensitiveMatch: true
            });

            $routeProvider.when("/DataCollection/Interviewers", {
                templateUrl: "App/DataCollection/views/_Interviewers.html",
                caseInsensitiveMatch: true
            });
            $routeProvider.when("/:lang/DataCollection/Interviewers", {
                templateUrl: "App/DataCollection/views/_Interviewers.html",
                caseInsensitiveMatch: true
            });


            $translateProvider
                .useSanitizeValueStrategy('sanitizeParameters')
                .useStaticFilesLoader({
                    prefix: 'App/DataCollection/translations/locale-',
                    suffix: '.json'
                });
        }]);

angular.module('dataCollectionPageApp')
    .filter('to_trusted', ['$sce', function ($sce) {
        return function (text) {
            return $sce.trustAsHtml(text);
        };
    }]);
